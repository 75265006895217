<template>
	<div class="auction-detail">
		<div class="top">
			<my-nft-list-nav :index="nav_index" :list="nav_list" @click="navTap"/>
		</div>
		<div class="main">
			<div class="main-top">
				<div class="card-name" v-if="is_mobile">{{ card.data.zhHans }}</div>
				<div class="main-top_left">
					<img class="main-top_bg" src="@/assets/bg_revoke.svg" alt="">
					<img class="main-top_img" :src="card_img" alt="">
				</div>
				<div class="main-top_right">
					<div class="name" v-if="!is_mobile">{{ card.data.zhHans }}</div>
					<div class="main-top_grid">
						<div class="main-top_item">
							<span>{{ $t('OWNER') }}</span>
							<span>{{ owner }}</span>
						</div>

						<div class="main-top_item">
							<span>{{ $t('PRICE') }}</span>
							<span>{{ card.price }}</span>
						</div>

						<div class="main-top_item">
							<span>{{ $t('YOUR BALANCE') }}</span>
							<span>{{ parseFloat(amt_balance) }}</span>
						</div>

						<div class="main-top_item" v-if="!is_combo">
							<span>{{ $t('MINING POWER') }}</span>
							<span>{{ card.data.mining_power }}</span>
						</div>

						<div class="main-top_item" v-if="is_combo">
							<span>{{ $t('GOD STRENGTH') }}</span>
							<span>{{ card.data.god_strength }}</span>
						</div>

						<div class="main-top_item" v-if="is_combo">
							<span>{{ $t('BONUS PROPORTION') }}</span>
							<span>{{ card.data.bonus_proportion }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="main-btn_list">
				<v-btn
						class="mr-5"
					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
					color="#EB6930"
					large
					outlined
					rounded
					@click="dialog = true"
				>{{ $t('BUY') }}</v-btn>
				<v-btn
						v-if="is_cancel"
						:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
						color="#EB6930"
						large
						rounded
						outlined
						:loading="cancel_loading"
						@click="_cancel"
				>{{ $t('CANCEL') }}</v-btn>
			</div>
		</div>

		<v-dialog v-model="dialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ $t('BUY NOW') }}</span>
					<v-btn @click="dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content auction-detail_dialog">
					<div class="dialog-content">
						<div class="dialog-content_left">
							<img class="main-top_bg" src="@/assets/bg_revoke.svg" alt="">
							<img class="main-top_img" :src="card_img" alt="">
						</div>
						<div class="dialog-content_right">
							<div class="dialog-content_title">{{ card.data.zhHans }}</div>

							<div v-if="!is_mobile">
								<div class="dialog-content_item">
									<span>{{ $t('YOUR BALANCE') }}</span>
									<span>{{ amt_balance }}</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('SERVICE FEE') }}</span>
									<span>5%</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('PRICE') }}</span>
									<span>{{ card.price }}</span>
								</div>

								<v-btn
									style="height: 52px !important;width: 280px;margin-top: 40px;"
									color="#EB6930"
									large
									rounded
									:loading="buy_loading"
									:disabled="btn_disable"
									@click="buyCard"
								>{{ is_approve ? $t('BUY') : $t('APPROVE') }}</v-btn>
							</div>
						</div>
					</div>
					<div v-if="is_mobile">
						<div class="dialog-content_item">
							<span>{{ $t('YOUR BALANCE') }}</span>
							<span>{{ Number(amt_balance).toFixed(4) }}</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('SERVICE FEE') }}</span>
							<span>5%</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('PRICE') }}</span>
							<span>{{ card.price }}</span>
						</div>

						<div class="text-center">
							<v-btn
									style="height: 36px !important;width: 148px;margin-top: 26px;"
									color="#EB6930"
									large
									rounded
									:loading="buy_loading"
									:disabled="btn_disable"
									@click="buyCard"
							>{{ is_approve ? $t('BUY') : $t('APPROVE') }}</v-btn>
						</div>
					</div>
				</div>
			</div>
		</v-dialog>

		<v-dialog v-model="completed_dialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ $t('Purchase Success') }}</span>
					<v-btn @click="dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>

				<div class="c-dialog__content">
					<div class="completed-box">
						<div class="completed-box_img">
							<img src="@/assets/bg_revoke.svg" alt="">
							<img :src="card_img" alt="">
						</div>
						<v-btn
							:style="is_mobile ? {height: '34px !important'} : {height: '52px !important'}"
							color="#EB6930"
							large
							rounded
							:loading="cancel_loading"
							@click="completed_dialog = false"
						>{{ $t('confirm') }}</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import { buy, getAMTAmt, transactionIsApprove, transactionApprove, cancel } from '@/utils/cardFactory'
import { getCardImgUrl } from '@/utils/tools'
import { SessionStorage } from 'storage-manager-js'
import { mapState } from 'vuex'
import eventBus from '@/utils/eventBus'
import Big from 'big.js'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'

export default {
	name: 'Buy',
	components: { MyNftListNav },
	data () {
		return {
			dialog: false,
			card: null,
			buy_loading: false,
			amt_balance: 0,
			is_approve: false,
			nav_list: [],
			nav_index: 0,
			pc_btn_styles: {
				height: '52px !important',
				width: '237px',
			},
			mobile_btn_styles: {
				height: '34px !important',
				width: '208px',
			},
			cancel_loading: false,
			completed_dialog: false,
		}
	},
	computed: {
		...mapState(['ADDRESS']),
		card_img () {
			return getCardImgUrl(this.card.type)
		},
		is_combo () {
			return this.card.type.split('-')[0] === '9'
		},
		owner () {
			return `${this.card.seller.slice(0, 4)}...${this.card.seller.substr(-4)}`
		},
		total_cost () {
			const price = new Big(this.card.price)
			const fee_pro = new Big(0.05)
			const fee = price.times(fee_pro)
			return price.plus(fee)
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
		is_cancel () {
			return this.card.seller.toUpperCase() === this.ADDRESS.toUpperCase()
		},
		btn_disable () {
			return (Number(this.amt_balance) < Number(this.card.price)) && this.is_approve
		},
	},
	created () {
		this.card = SessionStorage.get('BUY_INFO')
		this.nav_list = SessionStorage.get('MARKET_NAV')
		const { index } = this.$route.query
		if (index) {
			this.nav_index = Number(index)
		}
		if (this.ADDRESS) {
			this._amtBalance()
			this._transactionIsApprove()
		}
		eventBus.$on('connect-event', (res) => {
			this._amtBalance()
			this._transactionIsApprove()
		})
	},
	methods: {
		// 取消
		async _cancel () {
			this.cancel_loading = true
			try {
				await cancel(this.ADDRESS, this.card.index)
				this.cancel_loading = false
				this.$router.back()
			} catch (err) {
				this.cancel_loading = false
			}
		},
		navTap ({ index }) {
			this.$router.push(`/app/NFTMarket?index=${index}`)
		},
		async _transactionIsApprove () {
			this.is_approve = await transactionIsApprove(this.ADDRESS)
		},
		async _transactionApprove () {
			try {
				await transactionApprove(this.ADDRESS)
				this.buy_loading = false
				this.is_approve = true
			} catch (err) {
				this.buy_loading = false
			}
		},
		async _amtBalance () {
			this.amt_balance = await getAMTAmt(this.ADDRESS)
		},
		// 购买
		async buyCard () {
			this.buy_loading = true
			if (this.is_approve) {
				try {
					await buy(this.ADDRESS, this.card.index, this.card.tokenId)
					this.buy_loading = false
					this.dialog = false
					this.completed_dialog = true
				} catch (err) {
					this.dialog = false
					this.buy_loading = false
				}
			} else {
				this._transactionApprove()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.completed-box {
	img {
		&:last-child {
			top: 0;
			left: 0;
			position: absolute;
		}
		object-fit: contain;
	}
	.completed-box_img {
		position: relative;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
}
// pc
@media (min-width: 960px) {
	.completed-box_img {
		img {
			width: 276px;
			height: 346px;
		}
		margin-bottom: 20px;
	}
	.auction-detail {
		.main {
			padding: 0 80px 80px;
		}
		.main-top_left {
			width: 276px;
			height: 346px;
			margin-right: 95px;
			margin-top: -70px;
		}
		.main-top {
			display: flex;
		}
		.main-btn_list {
			padding-top: 80px;
		}
		.top {
			padding: 80px 0 110px;
		}
	}
}
// mobile
@media (max-width: 960px) {
	.completed-box_img {
		img {
			width: 141px;
			height: 176.75px;
		}
		margin-bottom: 10px;
	}
	.auction-detail {
		.card-name {
			position: absolute;
			left: 159px;
			top: 8px;
			z-index: 2;

			font-weight: bold;
			font-size: 18px;
			line-height: 27px;
			color: #E8E8E8;
		}
		.top {
			padding: 15px 15px 36px;
		}
		.main-btn_list {
			margin-top: 40px;
			text-align: center;
		}
		.main-top_grid {
			margin-top: 37px;
		}
		.main-top_item {
			font-size: 14px;
		}
		.main-top {
			display: flex;
			flex-direction: column;
			position: relative;
		}
		.main {
			padding: 0 15px 43px;
		}
		.main-top_left {
			width: 141px;
			height: 176.75px;
			margin-top: -28px;
		}
	}
}

.auction-detail {
	.main-top_right {
		.name {
			padding: 38px 0 40px;
			font-weight: bold;
			font-size: 36px;
			line-height: 54px;
			color: #E8E8E8;
		}
	}
	.main-top_left {
		.main-top_img {
			position: absolute;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main-top_item {
		&:after {
			content: " ";
			width: 10px;
			height: 10px;
			background-color: #EB6930;
			position: absolute;
			left: 0;
			transform: rotate(45deg);
		}
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 22px;
		margin-bottom: 16px;
	}
	.main {
		.main-btn_list {
			width: 100%;
			max-width: 931px;
		}
		background-color: #202831;
	}
}
</style>
